import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const PostCard = ({ data }) => {
  return (
    <Link
      className="media-card"
      to={data.node.frontmatter.slug}
      title={data.node.frontmatter.title}
    >
      <div className="flex-col">
        <h4>{data.node.frontmatter.title}</h4>
        <div className="card-info">
          <span>{data.node.frontmatter.description}</span>
          <br /> <br />
          <span>{data.node.frontmatter.date}</span>
        </div>
      </div>
      <div className="flex-col">
        <div className="media-logo">
          <GatsbyImage
            className="img"
            image={getImage(data.node.frontmatter.Image)}
            alt={data.node.frontmatter.title}
            placeholder="blurred"
            formats={["auto", "webp", "avif"]}
          />
          <div className="btn black">Devamını Oku</div>
        </div>
      </div>
    </Link>
  )
}

PostCard.propTypes = {
  data: PropTypes.object,
}

export default PostCard
