import React from "react"
import PropTypes from "prop-types"
import PostCard from "./PostCard"

const PostCardGroup = ({ posts }) => (
  <div className="section media-featured">
    <div className="wrap-wide">
      <div className="flex-row">
        {posts &&
          posts.map(post => <PostCard key={post.node.id} data={post} />)}
      </div>
    </div>
  </div>
)

PostCardGroup.propTypes = {
  posts: PropTypes.array.isRequired,
}

export default PostCardGroup
