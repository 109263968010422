import React, { Fragment } from "react"
import { graphql } from "gatsby"
import { Hero } from "../components/StandartPage/index"
import BlogCard from "../components/PostCard/PostCardGroup"

import Seo from "../components/seo"

const BlogPage = ({ data: { blog } }) => (
  <Fragment>
    <Seo title="Bilişim Hukuku Makaleler - Fenomen - Ünlü - Avukat" />
    <Hero
      title="Makaleler"
      subtitle="Bilişim Hukuku Alanında Yazdığımız Makalelere Göz Atın."
    />
    <BlogCard posts={blog.edges} />
  </Fragment>
)

export default BlogPage

export const blogQuery = graphql`
  {
    blog: allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      limit: 1000
    ) {
      edges {
        node {
          id
          frontmatter {
            date
            slug
            tags
            title
            description
            Image {
              childImageSharp {
                gatsbyImageData(width: 500)
              }
            }
          }
        }
      }
    }
  }
`
